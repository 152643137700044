import { INSURER_CONFIG } from '@shared/insurer';
export var InsurerFeatureFlag;
(function (InsurerFeatureFlag) {
    InsurerFeatureFlag["PersonalRobbery"] = "PersonalRobbery";
    InsurerFeatureFlag["MagneticPassRobberyMethod"] = "MagneticPassRobberyMethod";
    InsurerFeatureFlag["CompensationPropositionGenerali"] = "CompensationPropositionGenerali";
    InsurerFeatureFlag["NoBackHomeButton"] = "NoBackHomeButton";
    InsurerFeatureFlag["SplashScreen"] = "SplashScreen";
    InsurerFeatureFlag["IsLargeSplashScreen"] = "IsLargeSplashScreen";
    InsurerFeatureFlag["AllowNoStolenPersonalProperty"] = "AllowNoStolenPersonalProperty";
    InsurerFeatureFlag["LostKeys"] = "LostKeys";
    InsurerFeatureFlag["LanguageSelection"] = "LanguageSelection";
    InsurerFeatureFlag["ReturnToCustomerArea"] = "ReturnToCustomerArea";
    InsurerFeatureFlag["SmsSending"] = "SmsSending";
    InsurerFeatureFlag["InformationModalThreshold"] = "InformationModalThreshold";
    InsurerFeatureFlag["InformationModalBreakInRobberyThreshold"] = "InformationModalBreakInRobberyThreshold";
    InsurerFeatureFlag["IsHomeSecuredBackoffice"] = "IsHomeSecuredBackoffice";
    InsurerFeatureFlag["EditEventTypeBackoffice"] = "EditEventTypeBackoffice";
    InsurerFeatureFlag["ManagementBarBackOffice"] = "ManagementBarBackOffice";
    InsurerFeatureFlag["DisplayDirectlyContactOnHelp"] = "DisplayDirectlyContactOnHelp";
    InsurerFeatureFlag["MailButtonForPersonalProperties"] = "MailButtonForPersonalProperties";
    InsurerFeatureFlag["RENContactInformationConfirmation"] = "RENContactInformationConfirmation";
    InsurerFeatureFlag["DontDisplayImmediateAmountBlock"] = "DontDisplayImmediateAmountBlock";
    InsurerFeatureFlag["MultimediaModifiableCategory"] = "MultimediaModifiableCategory";
    InsurerFeatureFlag["ExternalContractSearch"] = "ExternalContractSearch";
    InsurerFeatureFlag["ExternalClaimCreation"] = "ExternalClaimCreation";
    InsurerFeatureFlag["DisplayRobbedDamagesReportCertificationCheckbox"] = "DisplayRobbedDamagesReportCertificationCheckbox";
    InsurerFeatureFlag["DisableFoodDamage"] = "DisableFoodDamage";
    InsurerFeatureFlag["NonBlockingRequiredFiles"] = "NonBlockingRequiredFiles";
    InsurerFeatureFlag["BackofficeDigitalReport"] = "BackofficeDigitalReport";
    InsurerFeatureFlag["BackofficeJustificativeDocuments"] = "BackofficeJustificativeDocuments";
    InsurerFeatureFlag["BackofficeHideThirdParties"] = "BackofficeHideThirdParties";
    InsurerFeatureFlag["ClaimsCheckModeIsSwitch"] = "ClaimsCheckModeIsSwitch";
    InsurerFeatureFlag["DisplayUploadInvoiceForConfirmationStep"] = "DisplayUploadInvoiceForConfirmationStep";
    InsurerFeatureFlag["NeverShowIndemnisationMessage"] = "NeverShowIndemnisationMessage";
    InsurerFeatureFlag["DisplayCompensationProposalScreen"] = "DisplayCompensationProposalScreen";
    InsurerFeatureFlag["ShortDetailsProposition"] = "ShortDetailsProposition";
    InsurerFeatureFlag["CloseClaimDirectlyFromManagementBar"] = "CloseClaimDirectlyFromManagementBar";
    InsurerFeatureFlag["MagicLinkLoginCheckContractNumber"] = "MagicLinkLoginCheckContractNumber";
    InsurerFeatureFlag["HideNewClaimButtonInBOClaimList"] = "HideNewClaimButtonInBOClaimList";
    InsurerFeatureFlag["SelfCareEndedDisableManagementButton"] = "SelfCareEndedDisableManagementButton";
    InsurerFeatureFlag["HideBreakInDamagesRepairedButNeedsMoreWork"] = "HideBreakInDamagesRepairedButNeedsMoreWork";
    InsurerFeatureFlag["DisplayContractProblems"] = "DisplayContractProblems";
    InsurerFeatureFlag["ShowWaterDamageOutsiderInterventionHelper"] = "ShowWaterDamageOutsiderInterventionHelper";
    InsurerFeatureFlag["DisplayCaseNumberInNeedManagementScreen"] = "DisplayCaseNumberInNeedManagementScreen";
    InsurerFeatureFlag["DisplayContinueButtonInNeedManagementScreen"] = "DisplayContinueButtonInNeedManagementScreen";
    InsurerFeatureFlag["HideProfessionalInterventionDescription"] = "HideProfessionalInterventionDescription";
    InsurerFeatureFlag["DisplayPersonalPropertiesDetails"] = "DisplayPersonalPropertiesDetails";
    InsurerFeatureFlag["ShowConfirmationContactDetailsForm"] = "ShowConfirmationContactDetailsForm";
    InsurerFeatureFlag["HideTitleTenantInsurerSupported"] = "HideTitleTenantInsurerSupported";
    InsurerFeatureFlag["HideContractNumberReminder"] = "HideContractNumberReminder";
    InsurerFeatureFlag["HideButtonReturnCustomerArea"] = "HideButtonReturnCustomerArea";
    InsurerFeatureFlag["AddContractNumberToCustomerAreaUrl"] = "AddContractNumberToCustomerAreaUrl";
    InsurerFeatureFlag["HideHelpLink"] = "HideHelpLink";
    InsurerFeatureFlag["OutputVideoExpertise"] = "OutputVideoExpertise";
    InsurerFeatureFlag["DisplayWaitingForManagementWording2"] = "DisplayWaitingForManagementWording2";
    InsurerFeatureFlag["HideRealEstateReFundAppliedDelayedDescription"] = "HideRealEstateReFundAppliedDelayedDescription";
    InsurerFeatureFlag["CreateClaimByDepartment"] = "CreateClaimByDepartment";
    InsurerFeatureFlag["DisplayDateAndTypeWhenComingFromPriming"] = "DisplayDateAndTypeWhenComingFromPriming";
})(InsurerFeatureFlag || (InsurerFeatureFlag = {}));
const insurerFeatureOptionsToMap = (features) => new Map(features === null || features === void 0 ? void 0 : features.map((featureOption) => {
    if (typeof featureOption === 'string') {
        return [featureOption, true];
    }
    else {
        const [feature, ...types] = featureOption;
        return [feature, types];
    }
}));
const checkFeatureFlag = (featureOptions, feature, trigram) => {
    const features = insurerFeatureOptionsToMap(featureOptions);
    const featureData = features.get(feature);
    if (typeof featureData === 'boolean')
        return featureData;
    if (Array.isArray(featureData)) {
        if (!trigram)
            return featureData.length > 0;
        return !featureData.length || featureData.includes(trigram);
    }
    return false;
};
export function insurerHasFeature(feature, trigram) {
    const featureOptions = this
        ? this.$config.insurerConfig.declaration.features
        : INSURER_CONFIG === null || INSURER_CONFIG === void 0 ? void 0 : INSURER_CONFIG.declaration.features;
    return checkFeatureFlag(featureOptions, feature, trigram);
}
